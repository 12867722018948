












import { Component, Vue } from "vue-property-decorator";
import TheHeader from "@/components/Header.vue";
import { SMRoot } from "./store";

@Component({
  components: {
    TheHeader,
  },
})
export default class App extends Vue {
  protected get views() {
    return SMRoot.views;
  }

  protected mounted() {
    SMRoot.startWatchingView();
    SMRoot.startWatchingViewport();
  }
}
