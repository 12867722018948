import { views } from "@/router/routes";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

type TNavLink = {
  name: string;
  label: string;
};

type TFixed = {
  height: number;
};

type TViewport = {
  width: number;
  height: number;
};

const getFixed = (): TFixed => {
  const el = document.getElementById("fixed");
  return {
    height: el?.offsetHeight ?? 0,
  };
};

const getViewport = (): TViewport => {
  const { clientWidth, clientHeight } = document.documentElement;
  return {
    width: clientWidth,
    height: clientHeight,
  };
};

const getElement = (id: string) => {
  const el = document.getElementById(id);
  return {
    top: el?.offsetTop ?? 0,
    bottom: (el?.offsetTop ?? 0) + (el?.offsetHeight ?? 0),
    id,
    el,
  };
};

@Module({ name: "Root" })
export default class Root extends VuexModule {
  fixed: TFixed = {
    height: 0,
  };

  @Mutation
  setFixed(payload: TFixed) {
    this.fixed = payload;
  }

  viewport: TViewport = {
    width: 0,
    height: 0,
  };

  get device() {
    const { width } = this.viewport;
    if (width < 600) return "mobile";
    if (width < 1024) return "tablet";
    return "desktop";
  }

  @Mutation
  setViewport(payload: TViewport) {
    this.viewport = payload;
  }

  views = views;
  currentViewID = views.length > 0 ? views[0].name : "";

  navLinks = views.reduce(
    (links, { name, label }) => (label ? links.concat({ name, label }) : links),
    [] as TNavLink[]
  );

  @Mutation
  setCurrentViewID(id: Root["currentViewID"]) {
    this.currentViewID = id;
  }

  @Action
  scrollTo(payload: Root["currentViewID"]) {
    if (this.currentViewID !== payload) {
      this.setCurrentViewID(payload);
    }
    const { top } = getElement(payload);
    const stickyHeight = getFixed().height;
    window.scrollTo({ top: top - stickyHeight, left: 0, behavior: "smooth" });
  }

  @Action
  startWatchingView() {
    window.addEventListener("scroll", () => {
      const elements = this.views.map(({ name: id }) => getElement(id));
      const stickyHeight = getFixed().height;
      const el = elements.find(
        (el) => window.scrollY + stickyHeight >= el.top && window.scrollY + stickyHeight < el.bottom
      );
      if (el) {
        const { id } = el;
        if (id && this.currentViewID !== id) {
          if (id === "modules") {
            if (this.currentViewID !== "modules-solutions") {
              this.setCurrentViewID("module-solutions");
            }
          } else if (id === "maps") {
            if (this.currentViewID !== "contact-us") {
              this.setCurrentViewID("contact-us");
            }
          } else {
            this.setCurrentViewID(id);
          }
        }
      }
    });
  }

  @Action
  startWatchingViewport() {
    const handler = () => {
      this.setFixed(getFixed());
      this.setViewport(getViewport());
    };
    handler();
    window.addEventListener("resize", handler);
  }
}
