























import { Component, Vue, Watch } from "vue-property-decorator";
import TheNav from "./Nav/Nav.vue";
import { TNavItem } from "./Nav/models";
import { SMRoot } from "@/store";

@Component({
  components: {
    TheNav,
  },
})
export default class TheHeader extends Vue {
  protected get fixedHeight() {
    return SMRoot.fixed.height;
  }

  protected menuOpened = false;

  @Watch("menuOpened", { immediate: true })
  protected openedOnChange(v: boolean) {
    document.documentElement.style.overflow = v ? "hidden" : "hidden auto";
  }

  protected get navLinks() {
    return SMRoot.navLinks;
  }

  protected get currentViewID() {
    return SMRoot.currentViewID;
  }

  protected get reorganizedLinks() {
    return this.navLinks.map(
      ({ name, label }): TNavItem => ({
        name,
        label,
        selected: name === this.currentViewID,
        action: () => {
          if (this.menuOpened) this.menuOpened = false;
          SMRoot.scrollTo(name);
        },
      })
    );
  }
}
