









import { Component, Prop, Vue } from "vue-property-decorator";
import { TNavItem } from "./models";

@Component
export default class TheNav extends Vue {
  @Prop({ type: Array, required: true }) readonly items!: TNavItem[];
}
