import { RouteConfig } from "vue-router";

class CView {
  name!: string;
  label!: string | null;

  constructor(name: CView["name"], label: CView["label"] = null) {
    this.name = name;
    this.label = label;
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const VTop = new CView("top");
const VServices = new CView("services", "Услуги");
const VWork = new CView("work", "Как мы работаем");
const VModuleSolutions = new CView("module-solutions", "Модульные решения");
const VModules = new CView("modules");
const VCompletedProjects = new CView("completed-projects", "Реализованные проекты");
const VContactUs = new CView("contact-us", "Будем на связи");
const VMaps = new CView("maps");
// const VContents = new CView("contents");

export const views: CView[] = [
  VTop,
  VServices,
  VWork,
  VModuleSolutions,
  VModules,
  VCompletedProjects,
  VContactUs,
  VMaps,
  // VContents,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const components: { [key: string]: any } = {};
const component = (name: string) => () =>
  import(/* webpackChunkName: "[request]" */ "@/sections/" + name + ".vue");
views.forEach(({ name }) => (components[name] = component(name)));

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "Layout",
    components,
  },

  {
    path: "*",
    redirect: "/",
  },
];

export default routes;
