import Vue from "vue";
import Vuex from "vuex";
import { getModule } from "vuex-module-decorators";
import Root from "./modules/root";

Vue.use(Vuex);

const modules = {
  Root,
};

const store = new Vuex.Store({
  modules,
});

export default store;

export const SMRoot = getModule(Root, store);
